import React from 'react';
import Wrapper from '../components/Wrapper';
import {
  pImg,
  mapIcon,
  bedIcon,
  bathtubIcon,
  areaIcon,
  approvedImg,
  zLogo,
  listingLogo,
} from '../assets';

const SellerDashboard = () => {
  return (
    <Wrapper>
      <section className="inner-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="font-red">Seller Dashobard</h1>
            </div>
            <div className="col-md-12">
              <div className="list-wrap">
                <div className="row">
                  <div className="col-md-9">
                    <div className="row align-items-center">
                      <div className="col-md-12 col-lg-7">
                        <div className="gal-wrap">
                          <div className="prot-lrg">
                            <img
                              src={pImg}
                              alt="Speed Estate"
                              className="img-fluid"
                            />
                          </div>
                          <div className="prot-list">
                            <ul>
                              <li>
                                <img
                                  src={pImg}
                                  alt="Speed Estate"
                                  className="img-fluid"
                                />
                              </li>
                              <li>
                                <img
                                  src={pImg}
                                  alt="Speed Estate"
                                  className="img-fluid"
                                />
                              </li>
                              <li>
                                <img
                                  src={pImg}
                                  alt="Speed Estate"
                                  className="img-fluid"
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-5">
                        <div className="prop-details">
                          <h3>My Property 1</h3>
                          <p className="location">
                            <img
                              src={mapIcon}
                              alt="map-icon"
                              className="icon"
                            />
                            117 W 13th St APT 47, New York, NY 10011
                          </p>
                          <ul>
                            <li>
                              <img
                                src={bedIcon}
                                alt="map-icon"
                                className="icon"
                              />
                              1 Bed
                            </li>
                            <li>
                              <img
                                src={bathtubIcon}
                                alt="map-icon"
                                className="icon"
                              />
                              1 Bathroom
                            </li>
                            <li>
                              <img
                                src={areaIcon}
                                alt="map-icon"
                                className="icon"
                              />
                              464 sqft
                            </li>
                          </ul>
                          <p className="price">$395,000</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="listing-status">
                      <h5>Property Listing Status</h5>

                      <ul>
                        <li>
                          <img src={zLogo} alt="Zillo" className="st-logo" />
                          <span>
                            <img src={approvedImg} alt="approved-icon" />
                            Approved
                          </span>
                        </li>
                        <li>
                          <img
                            src={listingLogo}
                            alt="Speed estate"
                            className="st-logo"
                          />
                          <span>
                            <img src={approvedImg} alt="approved-icon" />
                            Approved
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="list-wrap">
                <div className="row">
                  <div className="col-md-9">
                    <div className="row align-items-center">
                      <div className="col-md-12 col-lg-7">
                        <div className="gal-wrap">
                          <div className="prot-lrg">
                            <img
                              src={pImg}
                              alt="Speed Estate"
                              className="img-fluid"
                            />
                          </div>
                          <div className="prot-list">
                            <ul>
                              <li>
                                <img
                                  src={pImg}
                                  alt="Speed Estate"
                                  className="img-fluid"
                                />
                              </li>
                              <li>
                                <img
                                  src={pImg}
                                  alt="Speed Estate"
                                  className="img-fluid"
                                />
                              </li>
                              <li>
                                <img
                                  src={pImg}
                                  alt="Speed Estate"
                                  className="img-fluid"
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-5">
                        <div className="prop-details">
                          <h3>My Property 2</h3>
                          <p className="location">
                            <img
                              src={mapIcon}
                              alt="map-icon"
                              className="icon"
                            />
                            117 W 13th St APT 47, New York, NY 10011
                          </p>
                          <ul>
                            <li>
                              <img
                                src={bedIcon}
                                alt="map-icon"
                                className="icon"
                              />
                              1 Bed
                            </li>
                            <li>
                              <img
                                src={bathtubIcon}
                                alt="map-icon"
                                className="icon"
                              />
                              2 Bathroom
                            </li>
                            <li>
                              <img
                                src={areaIcon}
                                alt="map-icon"
                                className="icon"
                              />
                              500 sqft
                            </li>
                          </ul>
                          <p className="price">$495,000</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="listing-status">
                      <h5>Property Listing Status</h5>

                      <ul>
                        <li>
                          <img src={zLogo} alt="Zillo" className="st-logo" />
                          <span>
                            <img src={approvedImg} alt="approved-icon" />
                            Approved
                          </span>
                        </li>
                        <li>
                          <img
                            src={listingLogo}
                            alt="Speed estate"
                            className="st-logo"
                          />
                          <span>
                            <img src={approvedImg} alt="approved-icon" />
                            Approved
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default SellerDashboard;
