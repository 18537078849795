import React from 'react';
import { properties } from '../assets/mock-data';
import Wrapper from '../components/Wrapper';
import { areaIcon, bathtubIcon, bedIcon, mapIcon, pImg } from '../assets';

const BrowseHomes = () => {
  return (
    <Wrapper>
      <section className="inner-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="map-sticky p-1 border mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30420020.001550682!2d-109.68349102445721!3d43.56263791020437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1730448605069!5m2!1sen!2sin"
                  width="100%"
                  style={{ border: '0px' }}
                  allowFullScreen
                  loading="lazy"
                  title="google-map"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>

            <div className="col-md-7">
              <form className="row g-3 align-items-center mb-4">
                <div className="col-md-12 col-lg-4 brw-field-out">
                  <input
                    className="form-control brw-field rounded-pill"
                    type="text"
                    placeholder="Search"
                    id="example-search-input"
                  />
                  <span className="input-group-append brw-icon">
                    <i className="bi bi-search"></i>
                  </span>
                </div>

                <div className="col-md-12 col-lg-3">
                  <select
                    className="form-select rounded-pill brw-field"
                    id="specificSizeSelect"
                  >
                    <option defaultValue>All Status</option>
                    <option value="1">For Sale</option>
                    <option value="2">For Rent</option>
                    <option value="3">For Lease</option>
                  </select>
                </div>
                <div className="col-md-12 col-lg-3">
                  <select
                    className="form-select rounded-pill brw-field"
                    id="specificSizeSelect"
                  >
                    <option defaultValue>Home Type</option>
                    <option value="1">Apartment</option>
                    <option value="2">Bungalow</option>
                    <option value="3">Cottage</option>
                  </select>
                </div>

                <div className="col-md-12 col-lg-2 d-grid">
                  <button
                    type="submit"
                    className="btn btn-primary brw-field rounded-pill"
                  >
                    Submit
                  </button>
                </div>
              </form>

              <div className="row">
                <div className="col-md-12">
                  <h4 className="brw-hd">Browse Homes</h4>
                </div>
                <div className="row mb-3 align-items-center pe-0">
                  <div className="col-md-7">Showing 1 – 8 of 16 results</div>

                  <div className="col-md-5 pe-0">
                    <div className="row">
                      <label
                        htmlFor="colFormLabel"
                        className="shot-by col-sm-4 col-form-label"
                      >
                        Shot By
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-select rounded-pill brw-field"
                          id="specificSizeSelect"
                        >
                          <option defaultValue>Default</option>
                          <option value="1">Low to High</option>
                          <option value="2">High to Low</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="browse-wrap">
                <div className="row">
                  {properties.map((property) => (
                    <div key={property.id} className="col-md-12 col-lg-6 mb-4">
                      <div className="border p-3 rounded brw-box">
                        <div className="row align-items-center">
                          <div className="col-md-12 col-lg-12">
                            <div className="gal-wrap">
                              <div className="prot-img2">
                                <img
                                  src={pImg}
                                  alt="SpeedEstate"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-12">
                            <div className="prop-details2">
                              <h3>{property.title}</h3>
                              <p className="location2">
                                <img
                                  src={mapIcon}
                                  alt="map-icon"
                                  className="icon"
                                />
                                {property.location}
                              </p>
                              <ul>
                                <li>
                                  <img
                                    src={bedIcon}
                                    alt="map-icon"
                                    className="icon"
                                  />
                                  {property.beds} Bed
                                </li>
                                <li>
                                  <img
                                    src={bathtubIcon}
                                    alt="map-icon"
                                    className="icon"
                                  />
                                  {property.baths} Bathroom
                                </li>
                                <li>
                                  <img
                                    src={areaIcon}
                                    alt="map-icon"
                                    className="icon"
                                  />
                                  {property.area}
                                </li>
                              </ul>
                              <p className="price2">{property.price}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="row mt-4">
                <nav aria-label="Page navigation ">
                  <ul className="pagination browse-pagi justify-content-center">
                    <li className="page-item active" aria-current="page">
                      <button className="page-link">1</button>
                    </li>
                    <li className="page-item">
                      <button className="page-link">2</button>
                    </li>
                    <li className="page-item">
                      <button className="page-link">3</button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default BrowseHomes;
