import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { constant } from './constant';

const { AUTH_TOKEN } = constant;

const publicPaths = ['/', '/signin', '/signup', '/browse-homes', '/about-us'];

const isAuthenticated = () =>
  localStorage.getItem(AUTH_TOKEN) || sessionStorage.getItem(AUTH_TOKEN);

const Amplify = ({ children }) => {
  const authenticated = isAuthenticated();
  const location = useLocation();
  const currentPath = location.pathname;

  if (authenticated && (currentPath === '/signin' || currentPath === '/signup'))
    return <Navigate to="/" />;

  if (!authenticated && publicPaths.includes(currentPath))
    return <>{children}</>;

  if (!authenticated) return <Navigate to="/signin" />;

  return <>{children}</>;
};

export default Amplify;
