import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  AboutUs,
  AccountSettings,
  BrowseHomes,
  DeactivateAccount,
  Home,
  SellerDashboard,
  SellerHouseDetails,
  SellersVerification,
  SignIn,
  SignUp,
} from './pages';
import Amplify from './Amplify.js';

const Router = () => {
  return (
    <Amplify>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/account-settings" element={<AccountSettings />} />
        <Route path="/deactivate-account" element={<DeactivateAccount />} />
        <Route path="/browse-homes" element={<BrowseHomes />} />
        <Route path="/sellers-verification" element={<SellersVerification />} />
        <Route path="/dashboard" element={<SellerDashboard />} />
        <Route path="/sell" element={<SellerHouseDetails />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Amplify>
  );
};

export default Router;
