import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAboutUsContent } from './aboutUs.actions';
import { selectAboutUsContent } from './aboutUs.selectors';
import Wrapper from '../../components/Wrapper';

const AboutUs = () => {
  const dispatch = useDispatch();
  const aboutUsContent = useSelector(selectAboutUsContent);

  useEffect(() => {
    dispatch(getAboutUsContent());
  }, [dispatch]);

  return (
    <Wrapper>
      <div>
        <section className="inner-wrap">
          <div className="container">
            <div dangerouslySetInnerHTML={{ __html: aboutUsContent }} />
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

export default AboutUs;
