export const NavbarLinks = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'About Us',
    path: '/about-us',
  },
  {
    label: 'Sell',
    path: '/sell',
  },
  {
    label: 'Browse Homes',
    path: '/browse-homes',
  },
];
