import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Wrapper from '../components/Wrapper';

const SellerHouseDetailsSchema = Yup.object().shape({
  expectedPrice: Yup.string().required('Expected Price is required'),
  ownerName: Yup.string().required('Owner Name is required'),
  companyName: Yup.string(),
  coOwnerName: Yup.string(),
  addressLine1: Yup.string().required('Address Line 1 is required'),
  addressLine2: Yup.string(),
  addressLine3: Yup.string(),
  documentUpload: Yup.mixed(),
  imageUpload: Yup.mixed(),
});

const SellerHouseDetails = () => {
  const formik = useFormik({
    initialValues: {
      expectedPrice: '',
      ownerName: '',
      companyName: '',
      coOwnerName: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      documentUpload: null,
      imageUpload: null,
    },
    validationSchema: SellerHouseDetailsSchema,
    onSubmit: (values) => {
      console.log('Form Values:', values);
    },
  });

  return (
    <Wrapper>
      <section className="inner-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="font-red">Seller House Details</h1>
              <p className="info-txt2">
                <strong>List of Documents Required</strong>
                <span>
                  (Client to provide the documents required such as ID,
                  Passport, Title, Pink Slip etc.)
                </span>
              </p>
              <form onSubmit={formik.handleSubmit}>
                <div className="row mb-4 mt-4">
                  <div className="col-12 col-md-12 col-lg-6">
                    <input
                      type="name"
                      className="form-control txt-field-1"
                      name="expectedPrice"
                      placeholder="Expected Price"
                      value={formik.values.expectedPrice}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.expectedPrice &&
                    formik.errors.expectedPrice ? (
                      <div className="text-danger">
                        {formik.errors.expectedPrice}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-12 col-md-12 col-lg-6">
                    <input
                      type="text"
                      className="form-control txt-field-1"
                      name="ownerName"
                      placeholder="Owner Name"
                      value={formik.values.ownerName}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.ownerName && formik.errors.ownerName ? (
                      <div className="text-danger">
                        {formik.errors.ownerName}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-12 col-lg-6 mt-4 mt-md-4 mt-lg-0">
                    <div className="row align-items-center">
                      <div className="col-12 col-md-1 col-lg-1 otp-txt2">
                        or
                      </div>
                      <div className="col-12 col-md-11 col-lg-11">
                        <div className="row">
                          <div className="col-12">
                            <input
                              type="text"
                              name="companyName"
                              className="form-control txt-field-1"
                              placeholder="Company Name"
                              value={formik.values.companyName}
                              onChange={formik.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-4 mt-5">
                  <div className="col-12 col-md-12 col-lg-12">
                    <p>
                      <strong>NOTE:</strong> Name must match the documents
                    </p>
                  </div>
                  <div className="col-md-12 col-lg-8">
                    <input
                      type="text"
                      name="coOwnerName"
                      className="form-control txt-field-1"
                      placeholder="Co-Owner Name"
                      value={formik.values.coOwnerName}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-12 col-lg-4">
                    <input
                      type="text"
                      name="addressLine1"
                      className="form-control txt-field-1 mb-4 md-mb-0 mb-lg-0"
                      placeholder="Address Line 1"
                      value={formik.values.addressLine1}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.addressLine1 &&
                    formik.errors.addressLine1 ? (
                      <div className="text-danger">
                        {formik.errors.addressLine1}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-12 col-lg-4">
                    <input
                      type="text"
                      name="addressLine2"
                      className="form-control txt-field-1 mb-4 md-mb-0 mb-lg-0"
                      placeholder="Address Line 2"
                      value={formik.values.addressLine2}
                      onChange={formik.handleChange}
                    />
                  </div>

                  <div className="col-md-12 col-lg-4">
                    <input
                      type="text"
                      className="form-control txt-field-1"
                      name="addressLine3"
                      value={formik.values.addressLine3}
                      onChange={formik.handleChange}
                      placeholder="Address Line 3"
                    />
                  </div>
                </div>

                <div className="row mb-4 align-items-center">
                  <div className="col-md-12 col-lg-4">
                    <div className="input-group mb-3">
                      <input
                        type="file"
                        className="form-control browse-field"
                        id="inputGroupFile02"
                        accept="image/*"
                        onChange={(event) => {
                          formik.setFieldValue(
                            'documentUpload',
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                      <button
                        className="btn btn-primary btn-upload"
                        type="button"
                        id="button-addon2"
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-8 info-txt4">
                    <p>
                      Attorney provided documents will be listed here. Also, the
                      document to e-sign
                    </p>
                  </div>
                </div>

                <div className="row mb-4 align-items-center">
                  <div className="col-md-12 col-lg-4">
                    <div className="input-group mb-3">
                      <input
                        type="file"
                        className="form-control browse-field"
                        accept="image/*"
                        name="imageUpload"
                        onChange={(event) => {
                          formik.setFieldValue(
                            'imageUpload',
                            event.currentTarget.files[0]
                          );
                        }}
                        id="inputGroupFile02"
                      />
                      <button
                        className="btn btn-primary btn-upload"
                        type="button"
                        id="button-addon2"
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-8 info-txt4">
                    <p>
                      URL sample Upload Kitchen, Bathroom, Exterior, Other
                      images
                    </p>
                  </div>
                </div>

                <div className="row mb-4 mb-md-4 mb-lg-0">
                  <div className="col-md-12 info-txt3">
                    We are validating your documents and will notify you (via
                    Text & Email) in 48 hours
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-primary btn-blue inn-btn2 border-0"
                >
                  Submit to Validate
                </button>
              </form>
            </div>

            <div className="col-md-6"></div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default SellerHouseDetails;
