const properties = [
  {
    id: 1,
    title: 'Luxury Family Home',
    price: '$395,000',
    location: '117 W 13th St APT 47, New York, NY 10011',
    beds: 4,
    baths: 1,
    area: '400 sqft',
  },
  {
    id: 2,
    title: 'Eaton Garth Penthouse',
    price: '$180,000',
    location: '7722 18th Ave, Brooklyn',
    beds: 4,
    baths: 2,
    area: '450 sqft',
  },
  {
    id: 3,
    title: 'Modern Villa',
    price: '$700,000',
    location: '1234 Modern St',
    beds: 5,
    baths: 3,
    area: '500 sqft',
  },
  {
    id: 4,
    title: 'Cozy Cottage',
    price: '$250,000',
    location: '5678 Cozy Lane',
    beds: 3,
    baths: 2,
    area: '350 sqft',
  },
  {
    id: 5,
    title: 'Downtown Loft',
    price: '$480,000',
    location: '9101 Downtown Ave',
    beds: 2,
    baths: 1,
    area: '300 sqft',
  },
  {
    id: 6,
    title: 'Suburban House',
    price: '$325,000',
    location: '1122 Suburb St',
    beds: 4,
    baths: 2,
    area: '450 sqft',
  },
  {
    id: 7,
    title: 'Luxury Apartment',
    price: '$600,000',
    location: '3344 Luxe Dr',
    beds: 3,
    baths: 2,
    area: '400 sqft',
  },
  {
    id: 8,
    title: 'Penthouse Suite',
    price: '$950,000',
    location: '7788 High Rise',
    beds: 5,
    baths: 3,
    area: '550 sqft',
  },
];

export { properties };
