import React, { useRef } from 'react';

const OtpValidator = ({ otp, setOtp, className = '' }) => {
  const inputsRef = useRef([]);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.value && inputsRef.current[index + 1]) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && inputsRef.current[index - 1]) {
      inputsRef.current[index - 1].focus();
    }
  };

  return (
    <div className={` ${className}`}>
      <div className="row">
        {otp.map((data, index) => (
          <div className="col-3" key={index}>
            <input
              type="text"
              className="form-control otp-field-1"
              maxLength="1"
              value={data}
              onChange={(e) => handleChange(e.target, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              ref={(el) => (inputsRef.current[index] = el)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OtpValidator;
