import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import createStore from './store';
import rootReducer from './rootReducer.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const store = createStore(rootReducer);
function App() {
  return (
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
