import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import Wrapper from '../components/Wrapper';
import {
  formatPhoneNumber,
  getRawPhoneNumber,
} from '../utils/formatters/phoneNumber';
import { Button } from '../components/Elements';
import { loginUser, sendOtp } from '../reducers/users';
import { useDispatch } from 'react-redux';
import { OtpValidator } from '../components';
import { toast } from 'react-toastify';

const LoginSchema = Yup.object().shape({
  emailPhoneValue: Yup.string()
    .required('Email/Phone Number is required')
    .test('is-valid', 'Must be a valid email or phone number', (value) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phoneRegex = /^\+1 \d{3}-\d{3}-\d{4}$/;

      return emailRegex.test(value) || phoneRegex.test(value);
    }),
});

const SignInForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [otp, setOtp] = useState(new Array(4).fill(''));

  const handleSubmit = (values, setSubmitting) => {
    const { emailPhoneValue } = values;
    if (!emailPhoneValue) return;

    let contact = emailPhoneValue;
    let type = 'email';
    if (emailPhoneValue.startsWith('+1')) {
      type = 'phone';
      contact = getRawPhoneNumber(emailPhoneValue);
    }
    if (step === 0) {
      dispatch(
        sendOtp({
          contact,
          type: 'login',
        })
      )
        .then((res) => {
          if (res.payload) {
            toast.success('OTP sent successfully');
            setStep(1);
          } else {
            toast.error('Account does not exist.');
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setSubmitting(false));
    } else {
      if (otp.some((value) => !value)) return;

      dispatch(
        loginUser({
          contact,
          type,
          otp: otp.join(''),
        })
      )
        .then((res) => {
          if (res.payload) {
            navigate('/');
            localStorage.setItem('accessToken', res.payload.data.accessToken);
          } else toast.error('Invalid otp');
        })
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <Wrapper>
      <section className="inner-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 col-lg-5 offset-lg-3">
              <h1>
                Sign in or create
                <br />
                an account
              </h1>
              <p>
                <small>
                  By clicking "Continue," you agree to Speed Estate and its
                  affiliates’ <br />
                  <span className="text-primary pe-cursor">
                    {' '}
                    <u>Terms of Service</u>
                  </span>{' '}
                  and{' '}
                  <span className="text-primary pe-cursor underline">
                    <u> Privacy Policy</u>
                  </span>
                  .
                </small>
              </p>
              <Formik
                initialValues={{ emailPhoneValue: '' }}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting }) =>
                  handleSubmit(values, setSubmitting)
                }
              >
                {({ isSubmitting, values, setFieldValue }) => (
                  <Form>
                    <div className="row mt-4 mb-3">
                      {step === 0 ? (
                        <div className="col-md-12">
                          <label className="form-label">
                            Enter your email or mobile number *
                          </label>
                          <Field
                            type="emailPhoneValue"
                            className="form-control txt-field-1"
                            name="emailPhoneValue"
                            value={values.emailPhoneValue}
                            onChange={(e) => {
                              let inputValue = e.target.value;
                              if (
                                /^\d*$/.test(inputValue) ||
                                inputValue.startsWith('+1 ')
                              )
                                inputValue = formatPhoneNumber(inputValue);
                              setFieldValue('emailPhoneValue', inputValue);
                            }}
                          />
                          <ErrorMessage
                            name="emailPhoneValue"
                            component="div"
                            className="text-danger text-sm mt-1 px-2"
                          />
                        </div>
                      ) : (
                        <div className="col-md-12 w-full">
                          <label className="form-label">Enter your OTP</label>
                          <OtpValidator
                            otp={otp}
                            setOtp={setOtp}
                            className="w-full"
                          />
                        </div>
                      )}
                    </div>

                    <Button
                      btnText={step === 0 ? 'Send OTP' : 'Sign In'}
                      isLoading={isSubmitting}
                      isDisabled={isSubmitting}
                      type="submit"
                      className="btn btn-primary btn-blue inn-btn2 border-0 w-100"
                    />
                  </Form>
                )}
              </Formik>

              <p className="mt-5 text-center">
                Don't have an account?{' '}
                <Link to="/signup" className="font-org">
                  Sign-up
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default SignInForm;
