import React from 'react';
import { Footer, Navbar } from '../components';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div>
      <section className="home">
        <Navbar />

        <section className="home-mid">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>
                  No Broker <span>|</span> Low Competitive Cost <span>|</span>{' '}
                  One Stop Solution
                </h1>
                <p>Find out how much your property in worth?</p>

                <ul>
                  <li>
                    <span
                      className="btn btn-primary btn-blue home-btn rounded-pill border-0"
                      data-bs-toggle="modal"
                      data-bs-target="#popup-home"
                    >
                      Check Your Property Worth
                    </span>
                  </li>
                  <li>
                    <Link
                      to="/sell"
                      className="btn btn-primary btn-red home-btn rounded-pill border-0"
                    >
                      Sell Your Property
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade"
          id="popup-home"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title" id="staticBackdropLabel">
                  Check Your House Worth
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <iframe
                  src="https://www.zillow.com/"
                  title="Zillow"
                  width="100%"
                  height="500"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Home;
