import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation, Link } from 'react-router-dom';
import { NavbarLinks } from '../utils';
import classNames from 'classnames';
import { logo, signInIcon } from '../assets/index.js';
import { ROUTES } from '../constant';
import { selectUserDetails } from '../reducers/users/user.selectors.js';
import { getUserDetails } from '../reducers/users/user.actions.js';
import { useDispatch, useSelector } from 'react-redux';

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const userDetails = useSelector(selectUserDetails);

  const [isUserLoggedIn, setIsUserLoggedIn] = useState();

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    setIsUserLoggedIn(token);
  }, []);

  useEffect(() => {
    if (isUserLoggedIn)
      dispatch(getUserDetails()).then((res) => {
        if (!res.payload) localStorage.removeItem('accessToken');
      });
  }, [dispatch, isUserLoggedIn]);

  const handleSignOut = () => {
    setIsUserLoggedIn(false);
    localStorage.removeItem('accessToken');
    navigate(ROUTES.HOME);
  };

  return (
    <header>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-2 col-lg-2">
            <div className="logo pe-cursor">
              <Link to="/">
                <img src={logo} alt="Speed Estate" className="img-fluid" />
              </Link>
            </div>
          </div>

          <div className="col-md-8 col-lg-8">
            <div className="menus">
              <ul className="nav justify-content-center">
                {NavbarLinks.map(({ path, label }, index) => (
                  <li className="nav-item" key={path}>
                    <NavLink to={path} className="nav-link">
                      <span>{label}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-md-2 col-lg-2">
            {isUserLoggedIn ? (
              <div className="top-rgt2">
                <div className="btn-group">
                  <span
                    className="dropdown-toggle text-success fw-bold pe-cursor "
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {userDetails.name}
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/account-settings">
                        Your Account
                      </Link>
                    </li>
                    <li>
                      <button onClick={handleSignOut} className="dropdown-item">
                        Sign Out
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className="top-rgt">
                <ul>
                  <li
                    className={classNames({
                      hidden: pathname === ROUTES.SIGN_IN,
                    })}
                  >
                    <button
                      onClick={() => navigate(ROUTES.SIGN_IN)}
                      className="btn btn-primary signin-btn rounded-pill border-0"
                    >
                      <span>
                        <img src={signInIcon} alt="signin-icon" />
                      </span>
                      Sign In
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
