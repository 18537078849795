import React from 'react';
import Wrapper from '../components/Wrapper';

const SellersVerification = () => {
  return (
    <Wrapper>
      <section className="inner-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="font-red">Sellers Verification</h1>
              <p className="info-txt2">
                <strong>
                  The Documents Verification Completed By Speed Estate Office
                </strong>
              </p>
              <form>
                <button
                  type="submit"
                  className="btn btn-primary btn-blue inn-btn2 border-0 mt-5"
                >
                  Procced For Listing
                </button>
              </form>
            </div>

            <div className="row mt-5">
              <div className="col-md-12 info-txt3">
                Upon completion Speed Estate will notify the Seller via Text and
                Email that Verification is Complete and their home is Listed in
                the market
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default SellersVerification;
