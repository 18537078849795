import React from 'react';
import { appleImg, playstore } from '../assets';
import { NavbarLinks } from '../utils';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-12 app-link">
            <ul>
              <li>
                <a
                  href="https://www.apple.com/in/app-store/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={appleImg}
                    alt="apple play store"
                    className="img-fluid"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://play.google.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={playstore} alt="play store" className="img-fluid" />
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-12 col-lg-6 offset-lg-3">
            <div className="f-link">
              <ul className="nav justify-content-center">
                {NavbarLinks.map(({ path, label }, index) => (
                  <li className="nav-item" key={path}>
                    <NavLink to={path} className="nav-link">
                      <span>{label}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-md-12 col-lg-6 offset-lg-3">
            <div className="sm-link">
              <ul className="nav justify-content-center">
                <li>
                  <a
                    href="https://www.facebook.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi bi-facebook"></i>
                  </a>
                </li>

                <li>
                  <a href="https://x.com/" target="_blank" rel="noreferrer">
                    <i className="bi bi-twitter-x"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi bi-youtube"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi bi-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi bi-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-12 copyright-txt">
            Pellentesque egestas elementum egestas faucibus sem. Velit nunc
            egestas ut morbi. Leo diam diam nibh eget.Pellentesque egestas
            elementum egestas faucibus sem. Velit nunc egestas ut morbi. Leo
            diam diam nibh eget.Pellentesque egestas elementum egestas faucibus
            sem. Velit nunc egestas ut morbi. Leo diam diam nibh
            eget.Pellentesque egestas elementum egestas faucibus sem. Velit nunc
            egestas ut morbi. Leo diam diam nibh eget.
          </div>

          <div className="col-md-12 text-center copyright">
            SpeedEstate © 2024. All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
