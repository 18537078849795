import { createSlice } from '@reduxjs/toolkit';
import * as asyncActions from './aboutUs.actions';

const initialState = {
  aboutUsContent: '',
};

const slice = createSlice({
  name: 'aboutUs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      asyncActions.getAboutUsContent.fulfilled,
      (state, action) => {
        state.aboutUsContent = action.payload.data;
      }
    );
  },
});

export default slice;

export const { name, actions, reducer } = slice;
