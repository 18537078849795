import reducers from './user.reducers';
import * as selectors from './user.selectors';
import * as userActions from './user.actions';
export const {
  registerUser,
  emailExists,
  phoneExists,
  sendOtp,
  loginUser,
  deleteUserAccount,
  sendDeleteOtp,
} = userActions;

export const {
  name,
  reducer,
  actions: { sendOtpOn },
} = reducers;
export const { selectUser, selectUserDetails, selectApiSuccess } = selectors;
