import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Wrapper from '../components/Wrapper';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserDetails } from '../reducers/users';
import { formatPhoneNumber } from '../utils/formatters/phoneNumber';
import { ROUTES } from '../constant';

const AccountSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
});

const AccountSettings = () => {
  const userDetails = useSelector(selectUserDetails);
  const navigate = useNavigate();

  const getNames = (fullName) => {
    const names = fullName.trim().split(' ');
    const firstName = names[0];
    const lastName = names.length > 1 ? names.slice(1).join(' ') : '';
    return { firstName, lastName };
  };

  const formik = useFormik({
    initialValues: userDetails?.name
      ? getNames(userDetails.name)
      : { firstName: '', lastName: '' },
    validationSchema: AccountSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log('Form Values:', values);
    },
  });

  const handleSignOut = () => {
    localStorage.removeItem('accessToken');
    navigate(ROUTES.HOME);
  };

  return (
    <Wrapper>
      <section className="inner-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-lg-2 mb-4">
              <Link
                to="/dashboard"
                className="btn btn-primary btn-blue d-block border-0 mb-3 set-btn"
              >
                Your Listing <i className="bi bi-list-task"></i>
              </Link>
              <button
                className="btn btn-primary btn-red d-block border-0 set-btn w-100"
                onClick={handleSignOut}
              >
                Sign Out <i className="bi bi-box-arrow-right"></i>
              </button>
              <Link
                to="/deactivate-account"
                className="btn btn-primary btn-danger d-block border-0 mt-3 set-btn"
              >
                Delete Account <i className="bi bi-trash3"></i>
              </Link>
            </div>

            <div className="col-md-7 col-lg-9 offset-md-1 offset-lg-1">
              <form>
                <section className="set-wrap">
                  <div className="row">
                    <div className="col-md-8">
                      <h2>Your Info</h2>
                    </div>
                    <div
                      className="col-md-4 save-btn pe-cursor"
                      onClick={formik.onSubmit}
                    >
                      <span>
                        Save <i className="bi bi-floppy"></i>{' '}
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-6">
                      <label htmlFor="name" className="form-label">
                        First Name *
                      </label>
                      <input
                        className="form-control txt-field-1"
                        id="firstName"
                        placeholder="David"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-12 col-lg-6">
                      <label htmlFor="name" className="form-label">
                        Last Name *
                      </label>
                      <input
                        className="form-control txt-field-1"
                        id="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        placeholder="Goggins"
                      />
                    </div>
                  </div>
                </section>

                <section className="set-wrap mt-5">
                  <div className="row">
                    <div className="col-md-8">
                      <h2>Contact Info</h2>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-6">
                      <label htmlFor="phone" className="form-label">
                        Phone *
                      </label>
                      <input
                        type="phone"
                        className="form-control txt-field-1"
                        id="phone"
                        value={formatPhoneNumber(userDetails.phonenumber) || ''}
                        aria-label="Disabled input example"
                        disabled
                        readOnly
                      />
                    </div>
                    <div className="col-12 col-md-12 col-lg-6">
                      <label htmlFor="email" className="form-label">
                        Email *
                      </label>
                      <input
                        type="email"
                        className="form-control txt-field-1"
                        id="email"
                        value={userDetails.email || ''}
                        aria-label="Disabled input example"
                        disabled
                        readOnly
                      />
                    </div>
                  </div>
                </section>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default AccountSettings;
