import classNames from 'classnames';
import React from 'react';

const Button = ({
  btnText = '',
  className = '',
  isLoading = false,
  isDisabled = false,
  onSubmit = () => {},
  type = 'button',
}) => {
  return (
    <button
      type={type}
      className={classNames(
        'd-flex align-items-center justify-content-center',
        className
      )}
      disabled={isDisabled}
      onClick={type === 'submit' ? undefined : onSubmit}
    >
      {isLoading ? (
        <div className="spinner-border text-light" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        btnText
      )}
    </button>
  );
};

export default Button;
