export const getRawPhoneNumber = (formattedValue) => {
  const rawNumber = formattedValue?.replace(/\D/g, '');
  return rawNumber.length > 1 ? rawNumber.slice(1) : rawNumber;
};

export const formatPhoneNumber = (value) => {
  const cleaned = value?.replace(/\D/g, '').replace(/^1/, '');
  if (cleaned) {
    let formatted = '+1';

    if (cleaned.length > 0) formatted += ` ${cleaned.slice(0, 3)}`;

    if (cleaned.length > 3) formatted += `-${cleaned.slice(3, 6)}`;

    if (cleaned.length > 6) formatted += `-${cleaned.slice(6, 10)}`;

    return formatted;
  }
  return '';
};
