import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import Wrapper from '../components/Wrapper';
import {
  formatPhoneNumber,
  getRawPhoneNumber,
} from '../utils/formatters/phoneNumber';
import { Button } from '../components/Elements';
import {
  deleteUserAccount,
  selectUserDetails,
  sendDeleteOtp,
} from '../reducers/users';
import { useDispatch, useSelector } from 'react-redux';
import { OtpValidator } from '../components';
import { toast } from 'react-toastify';

const DeactivateSchema = Yup.object().shape({
  emailPhoneValue: Yup.string()
    .required('Email/Phone Number is required')
    .test('is-valid', 'Must be a valid email or phone number', (value) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phoneRegex = /^\+1 \d{3}-\d{3}-\d{4}$/;

      return emailRegex.test(value) || phoneRegex.test(value);
    }),
});

const DeactivateAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector(selectUserDetails);

  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState(new Array(4).fill(''));

  const formik = useFormik({
    initialValues: {
      emailPhoneValue: userDetails.email || userDetails.phonenumber || '',
    },
    validationSchema: DeactivateSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values, args) => {
    const { emailPhoneValue } = values;
    if (!emailPhoneValue) return;

    let contact = emailPhoneValue;
    if (emailPhoneValue.startsWith('+1'))
      contact = getRawPhoneNumber(emailPhoneValue);

    if (step === 0) {
      setIsLoading(true);
      dispatch(sendDeleteOtp({ contact }))
        .then((res) => {
          if (res.payload) {
            toast.success('OTP sent successfully');
            setStep(1);
          } else toast.error('Account does not exist.');
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    } else {
      if (otp.some((value) => !value)) return;
      setIsLoading(true);

      dispatch(deleteUserAccount({ contact, otp: otp.join('') }))
        .then((res) => {
          if (res.payload) {
            navigate('/');
            localStorage.removeItem('accessToken');
          } else toast.error('Invalid OTP');
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <Wrapper>
      <section className="inner-wrap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 px-5">
              <h1>Deactivate Account</h1>
              <p>
                <small>
                  Please note that once you <b>deactivate</b> your account, you
                  will not be able to <b>Log in</b> again, and all your data
                  will be
                  <b> permanently deleted</b>.
                </small>
              </p>
              <form onSubmit={formik.handleSubmit}>
                <div className="row mt-4 mb-3">
                  {step === 0 ? (
                    <div className="col-md-12">
                      <label className="form-label">
                        Enter your email or mobile number *
                      </label>
                      <input
                        type="text"
                        name="emailPhoneValue"
                        className="form-control txt-field-1"
                        value={formik.values.emailPhoneValue}
                        onChange={(e) => {
                          let inputValue = e.target.value;
                          if (
                            /^\d*$/.test(inputValue) ||
                            inputValue.startsWith('+1 ')
                          )
                            inputValue = formatPhoneNumber(inputValue);
                          formik.setFieldValue('emailPhoneValue', inputValue);
                        }}
                      />
                      {formik.touched.emailPhoneValue &&
                      formik.errors.emailPhoneValue ? (
                        <div className="text-danger text-sm mt-1 px-2">
                          {formik.errors.emailPhoneValue}
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="col-md-12 w-full">
                      <label className="form-label">Enter your OTP</label>
                      <OtpValidator
                        otp={otp}
                        setOtp={setOtp}
                        className="w-75"
                      />
                    </div>
                  )}
                </div>
                <Button
                  btnText={step === 0 ? 'Send OTP' : 'Deactivate Account'}
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  type="submit"
                  className="btn btn-primary btn-blue inn-btn2 border-0 w-100"
                />
              </form>
              <p className="mt-5 text-center">
                Changed your mind?{' '}
                <Link to="/" className="font-org">
                  Go Back to Profile
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default DeactivateAccount;
