import { createSlice } from '@reduxjs/toolkit';
import {
  fetchUsers,
  getUserDetails,
  registerUser,
  sendOtp,
  deleteUserAccount,
} from './user.actions';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    value: 0,
    users: [],
    accessToken: '',
    apiSuccess: [],
    userDetails: {},
  },
  reducers: {
    sendOtpOn: (state, action) => {
      state.apiSuccess = state.apiSuccess.filter(
        (each) => each !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = [...action.payload];
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.accessToken = action.payload;
    });

    builder.addCase(sendOtp.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.apiSuccess = [...state.apiSuccess, action.meta.arg.type];
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.userDetails = action.payload.data;
    });
    builder.addCase(deleteUserAccount.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.userDetails = {};
    });
  },
});
export const { name, actions, reducer } = userSlice;

export default userSlice;
