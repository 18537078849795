import { createAsyncThunk } from '@reduxjs/toolkit';
import asyncAction from '../../infrastructure/asyncAction';

export const getUsers = createAsyncThunk(
  'users/getUsers',
  async (thunkArgs) =>
    await asyncAction({
      url: '',
      ...thunkArgs,
    })
);

export const getAboutUsContent = createAsyncThunk(
  'users/getUsers',
  async (thunkArgs) => {
    return await asyncAction({
      url: 'api/v1/about/about-us',
      ...thunkArgs,
    });
  }
);

export const getAboutUsContent1 = async () => {
  return await asyncAction({
    url: 'api/v1/about/about-us',
  });
};
