import AboutUs from './AboutUs';

import reducers from './aboutUs.reducer';
import * as selectors from './aboutUs.selectors';
import * as actions from './aboutUs.actions';
export const { getAboutUsContent } = actions;
export const { name, reducer } = reducers;
export const { selectAboutUsContent } = selectors;

export default AboutUs;
