import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/Elements';
import { ROUTES } from '../constant';
import {
  registerUser,
  phoneExists,
  selectApiSuccess,
  emailExists,
  sendOtp,
  sendOtpOn,
} from '../reducers/users';
import Wrapper from '../components/Wrapper';
import { OtpValidator } from '../components';
import {
  getRawPhoneNumber,
  formatPhoneNumber,
} from './../utils/formatters/phoneNumber';
const { SIGN_IN } = ROUTES;

const SignUpSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name must be at least 2 characters long')
    .max(50, 'Name cannot be longer than 50 characters')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is Required')
    .matches(
      /^[\w-.]+@[\w-.]+\.[\w-]{2,4}$/,
      'Email must be in a valid format (e.g., example@domain.com)'
    ),
  phone: Yup.string()
    .required('Phone number is required')
    .matches(/^\+1 \d{3}-\d{3}-\d{4}$/, 'Invalid phone number'),
  consent: Yup.boolean().oneOf(
    [true],
    'You must agree to receive messages and emails.'
  ),
});

const SignUpForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkApiSuccess = useSelector(selectApiSuccess);

  const [emailOtp, setEmailOtp] = useState(new Array(4).fill(''));

  const [phoneOtp, setPhoneOtp] = useState(new Array(4).fill(''));
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [isExisting, setIsExisting] = useState({
    email: false,
    phone: false,
  });

  useEffect(() => {
    const isEmailOtpFilled = emailOtp.every((digit) => digit !== '');

    const isPhoneOtpFilled = phoneOtp.every((digit) => digit !== '');

    if (
      isEmailOtpFilled &&
      isPhoneOtpFilled &&
      checkApiSuccess.includes('phone') &&
      checkApiSuccess.includes('email')
    ) {
      setIsButtonDisabled(false);
    } else setIsButtonDisabled(true);
  }, [emailOtp, phoneOtp, checkApiSuccess]);

  const checkEmailExists = (event) => {
    const email = event.target.value;
    const emailRegex = /^[\w-.]+@[\w-.]+\.[\w-]{2,4}$/;

    if (emailRegex.test(email)) {
      dispatch(emailExists(email)).then((res) => {
        if (!res.payload)
          setIsExisting({
            ...isExisting,
            email: true,
          });
        else {
          setIsExisting({
            ...isExisting,
            email: false,
          });
        }
      });
    }
  };

  const checkPhoneExists = (e) => {
    const phoneNumber = e.target.value;

    const cleanedPhoneNumber = getRawPhoneNumber(phoneNumber);
    if (cleanedPhoneNumber.length === 10) {
      dispatch(phoneExists(cleanedPhoneNumber)).then((res) => {
        if (!res.payload)
          setIsExisting({
            ...isExisting,
            phone: true,
          });
        else {
          setIsExisting({
            ...isExisting,
            phone: false,
          });
        }
      });
    }
  };

  // Handle form submission
  const handleRegistration = (values, setSubmitting) => {
    setSubmitting(true);
    const { email, phone, name } = values;
    const phonenumber = getRawPhoneNumber(phone);

    dispatch(
      registerUser({
        email,
        phonenumber,
        name,
        otpEmail: emailOtp.join(''),
        otpMobile: phoneOtp.join(''),
      })
    )
      .then((res) => {
        if (res.payload) navigate(SIGN_IN);
        else toast.error('Failed to register. Try agian!');
      })
      .finally(() => setSubmitting(false));
  };

  const otpHandler = (contact, type) => {
    dispatch(
      sendOtp({
        contact,
        type,
      })
    )
      .then((res) => {
        if (res.payload) toast.success(`OTP sent successfully on ${type}.`);
        else toast.error(`Failed to send OTP on ${type}, Please re-send.`);
      })
      .catch((err) => console.log(err));
  };

  const handleSendOtp = (values, setSubmitting) => {
    const { phone, email } = values;
    if (!phone || !email) return;
    setSubmitting(true);
    if (!checkApiSuccess.includes('phone'))
      otpHandler(getRawPhoneNumber(phone), 'phone');
    if (!checkApiSuccess.includes('email')) otpHandler(email, 'email');
    setSubmitting(false);
  };

  const handleResendOtp = (values) => {
    const { email, phone } = values;
    if (!email && !phone) return;
    otpHandler(getRawPhoneNumber(phone), 'phone');
    otpHandler(email, 'email');
  };

  return (
    <Wrapper>
      <section className="inner-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="font-red">Seller Registration</h1>

              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  phone: '',
                  consent: false,
                }}
                validationSchema={SignUpSchema}
                onSubmit={(values, { setSubmitting }) =>
                  handleSendOtp(values, setSubmitting)
                }
              >
                {({
                  isSubmitting,
                  values,
                  errors,
                  setFieldValue,
                  setSubmitting,
                }) => (
                  <Form>
                    <div className="row mb-4 mt-4">
                      <div className="col-12 col-md-12 col-lg-6">
                        <Field
                          type="Name"
                          className="form-control txt-field-1"
                          placeholder="Name *"
                          name="name"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger text-sm mt-1 px-2"
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-md-12 col-lg-6">
                        <Field
                          type="phone"
                          className="form-control txt-field-1"
                          placeholder="Phone Number *"
                          name="phone"
                          value={formatPhoneNumber(values.phone)}
                          onChange={(e) => {
                            dispatch(sendOtpOn('phone'));
                            setFieldValue(
                              'phone',
                              formatPhoneNumber(e.target.value)
                            );
                          }}
                          onBlur={checkPhoneExists}
                        />
                        {isExisting.phone && (
                          <div className="text-danger text-sm mt-1 px-2">
                            User with this mobile already exists.
                          </div>
                        )}
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-danger text-sm mt-1 px-2"
                        />
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 mt-4 mt-md-4 mt-lg-0">
                        <div className="row align-items-center">
                          <div className="col-12 col-md-3 col-lg-3 otp-txt">
                            <strong>Validate Phone</strong>
                          </div>
                          <OtpValidator
                            otp={phoneOtp}
                            setOtp={setPhoneOtp}
                            className="col-8 col-md-6 col-lg-6"
                          />

                          <div className="col-4 col-md-3 col-lg-3">
                            Enter Phone OTP
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-4 mt-4">
                      <div className="col-12 col-md-12 col-lg-6">
                        <Field
                          type="email"
                          className="form-control txt-field-1"
                          placeholder="Email*"
                          name="email"
                          value={values.email}
                          onBlur={checkEmailExists}
                          onChange={(e) => {
                            dispatch(sendOtpOn('email'));
                            setFieldValue('email', e.target.value);
                          }}
                        />
                        {isExisting.email && (
                          <div className="text-danger text-sm mt-1 px-2">
                            User with this email already exists.
                          </div>
                        )}
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger text-sm mt-1 px-2"
                        />
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 mt-4 mt-md-4 mt-lg-0">
                        <div className="row align-items-center">
                          <div className="col-12 col-md-3 col-lg-3 otp-txt">
                            <strong>Validate Email</strong>
                          </div>
                          <OtpValidator
                            otp={emailOtp}
                            setOtp={setEmailOtp}
                            className="col-8 col-md-6 col-lg-6"
                          />

                          <div className="col-4 col-md-3 col-lg-3">
                            Enter Email OTP
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex flex-column flex-md-row justify-content-between mb-4 mb-md-4">
                      <div>
                        <label>
                          <Field
                            type="checkbox"
                            name="consent"
                            className="me-2 mb-4 mb-md-0"
                          />
                          I would like to receive messages/emails at the
                          phone/email provided.
                        </label>
                        <ErrorMessage
                          name="consent"
                          component="div"
                          className="text-danger text-sm mt-1 px-2"
                        />
                      </div>
                      <div className="s-info-txt">
                        2 factor authentication: An OTP will be sent to their
                        mobile phone or email
                      </div>
                    </div>

                    <div className="d-flex">
                      <Button
                        btnText="Send OTP"
                        type="submit"
                        isLoading={isSubmitting && !isButtonDisabled}
                        isDisabled={
                          (isExisting.phone ||
                            isExisting.email ||
                            checkApiSuccess.length === 2) &&
                          !values.consent
                        }
                        className="btn btn-primary btn-blue inn-btn2 border-0 mx-2"
                      />
                      <Button
                        btnText="Register"
                        isDisabled={isButtonDisabled || isSubmitting}
                        onSubmit={() =>
                          handleRegistration(values, setSubmitting)
                        }
                        className="btn btn-primary btn-blue inn-btn2 border-0"
                      />
                    </div>

                    {checkApiSuccess.length === 2 && (
                      <div className="mt-4">
                        <p>
                          Didn't receive the OTP?{' '}
                          <span
                            className="text-primary pe-cursor"
                            onClick={() => handleResendOtp(values)}
                          >
                            Resend OTP
                          </span>
                        </p>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>

            <div className="col-md-6"></div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default SignUpForm;
