import { createAsyncThunk } from '@reduxjs/toolkit';
import asyncAction from '../../infrastructure/asyncAction';
const AUTH_ROUTE = 'api/v1/auth';
const USER_ROUTE = 'api/v1/user';

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const response = await fetch('https://jsonplaceholder.typicode.com/users')
    .then((response) => response.json())
    .then((data) => data);
  return response;
});

export const registerUser = createAsyncThunk(
  'auth/registration',
  async ({ name, phonenumber, email, otpEmail, otpMobile }, thunkArgs) => {
    const response = await asyncAction({
      url: 'api/v1/auth/sign-up',
      methodType: 'post',
      httpHeaders: {
        body: JSON.stringify({
          name,
          email,
          phonenumber,
          otpEmail,
          otpMobile,
        }),
      },
      ...thunkArgs,
    });
    return response;
  }
);

export const emailExists = createAsyncThunk(
  'auth/email-exists',
  async (email, thunkArgs) =>
    await asyncAction({
      url: `${AUTH_ROUTE}/email-exists`,
      methodType: 'post',
      httpHeaders: {
        body: JSON.stringify({
          email,
        }),
      },
      ...thunkArgs,
    })
);

export const phoneExists = createAsyncThunk(
  'auth/phone-exists',
  async (phonenumber, thunkArgs) =>
    await asyncAction({
      url: `${AUTH_ROUTE}/mobile-exists`,
      methodType: 'post',
      httpHeaders: {
        body: JSON.stringify({
          phonenumber,
        }),
      },
      ...thunkArgs,
    })
);

export const sendOtp = createAsyncThunk(
  'auth/send-otp',
  async ({ type, contact }, thunkArgs) =>
    await asyncAction({
      url: `${AUTH_ROUTE}/send-otp`,
      methodType: 'post',
      httpHeaders: {
        body: JSON.stringify({
          type,
          contact,
        }),
      },
      ...thunkArgs,
    })
);

export const loginUser = createAsyncThunk(
  'auth/login',
  async ({ contact, type, otp }, thunkArgs) => {
    const response = await asyncAction({
      url: `${AUTH_ROUTE}/login`,
      methodType: 'post',
      httpHeaders: {
        body: JSON.stringify({
          contact,
          type,
          otp,
        }),
      },
      ...thunkArgs,
    });
    return response;
  }
);

export const getUserDetails = createAsyncThunk(
  'user/get-details',
  async (thunkArgs) => {
    return await asyncAction({
      url: `${USER_ROUTE}/user-info`,
      ...thunkArgs,
    });
  }
);

export const sendDeleteOtp = createAsyncThunk(
  'user/send-delete-otp',
  async ({ contact }, thunkArgs) =>
    await asyncAction({
      url: `${USER_ROUTE}/send-user-delete-otp`,
      methodType: 'post',
      httpHeaders: {
        body: JSON.stringify({
          contact,
        }),
      },
      ...thunkArgs,
    })
);
export const deleteUserAccount = createAsyncThunk(
  'user/delete-user-account',
  async (requestBody, thunkArgs) =>
    await asyncAction({
      url: `${USER_ROUTE}/delete-user-account`,
      methodType: 'post',
      httpHeaders: {
        body: JSON.stringify(requestBody),
      },
      ...thunkArgs,
    })
);
